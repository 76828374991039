import React from "react";
import { Container, Breadcrumb, Row, Col, Button} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Post } from "../../components/Core";
import Header from "../../components/HeaderPro";
import Footer from "../../components/FooterPro";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';
import ReactStars from "react-rating-stars-component";
import DataTable from '../../components/data/banquePro/banquePro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleRight} from '@fortawesome/free-solid-svg-icons'

function avis() {

	const go = '/go/?go=Anytime&url=https://www.awin1.com/cread.php?s=2094107&v=7244&q=310900&r=673721'
	
	const star1 = {size: 22,value: 4.2,color: "grey",activeColor: "#ffc107",edit: false,};
	const star2 = {size: 22,value: 4.1,color: "grey",activeColor: "#ffc107",edit: false,};
	const star3 = {size: 22,value: 4.4,color: "grey",activeColor: "#ffc107",edit: false,};
	const star4 = {size: 22,value: 3,color: "grey",activeColor: "#ffc107",edit: false,};
	const star5 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,};
	const star6 = {size: 22,value: 5,color: "grey",activeColor: "#ffc107",edit: false,}; 
	

    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Avis Anytime : à lire absolument avant d'ouvrir un compte</title>
                <meta name="description" content="
              Découvrez la banque pro Anytime. Autoentrepreneur à entreprise, la néobanque propose 5 formules à tarifs compétitifs. Voici l'avis Anytime de BankBank." />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/banque-pro/`} style={{color: 'black'}}>Banque Pro</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Avis Anytime</Breadcrumb.Item>
      	 </Breadcrumb>

	
	<Row>
		<Col sm={9}>  <h1 id='avish1' style={{color: 'rgb(41, 49, 61)'}}>Avis Anytime</h1>
          <h2 id='avish2' style={{marginLeft: 0, marginBottom: 25, color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Néobanque pour les entreprises et professionnels, Anytime répond aux attentes de la majorité des structures. Elle propose aussi espace open banking très tres interessant. Decouvrez notre avis Anytime :</h2>
	
 <a href={go} target='_blank' rel="noreferrer">
                <Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', borderColor: 'rgb(240 0 87)', color: 'white'}}>Offre Anytime <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
                </a>

		  
		  </Col>
	
		<Col sm={3} id='logoAvis'>
		<a href={go} target='_blank' rel="noreferrer">
		<StaticImage
		  	src='../../images/anytime-logo.png'
			width={250}
			alt='logo anytime'
			placeholder='tracedSVG'
			/>
</a>
		</Col>
	</Row>

		 
<hr></hr>
<Row>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Compte bancaire</p></Col>
			<Col> <ReactStars {...star1} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Carte bancaire</p></Col>
			<Col><ReactStars {...star2} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Tarifs</p></Col>
			<Col><ReactStars {...star3} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Application</p></Col>
			<Col><ReactStars {...star4} /></Col>
		</Row>
	</Col>
	<Col sm={4}>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Comptabilité</p></Col>
			<Col><ReactStars {...star5} /></Col>
		</Row>
		<Row>
			<Col><p style={{color: '#1D2D35', fontFamily: 'Garamond', fontSize: 22, letterSpacing: 'normal'}}>Gestion</p></Col>
			<Col><ReactStars {...star6} /></Col>
		</Row>
	</Col>
</Row>
<hr></hr>
<Post>



		<h2>Compte Pro Anytime</h2>
		<p>5 offres chez Anytime allant de  9.50€ /mois à 249€ /mois. La néobanque pro propose aussi une bankstore et un connection API puissante. L’offre est destinée à 4 types de structures :</p>
		<ul>
			<li>particuliers et indépendants (autoentrepreneur et freelance)</li>
			<li>sociétés déjà créées (EURL, SARL, SASU, SAS, SA)</li>
			<li>associations et SCI</li>
			<li>nouvelles entreprises (dépôt de capital)</li>
		</ul>

<iframe id='videoYT'src="https://www.youtube.com/embed/WZnSmEU-jcw" title="Avis Anytime" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


<hr></hr>
<h2>Carte bancaires Anytime</h2>
<p>
Anytime propose un nombre illimité de carte bancaire Mastercard physique ainsi que de cartes virtuelles.
</p><p>
Il y’a 4 offres de compte chez Anytime. La différence se fait sur les plafonds de paiements et réception de fonds ainsi que sur les services comptables intégrés.
</p>
<div style={{marginLeft: -5}}>
<DataTable row={[1,2,3,4,5,6]} id='postTable'/>
<DataTable row={[1,2,3,4,5,6]} id='mobileTable'/>

</div>

<h3>Solo</h3>
<ul>
	<li>3 cartes Mastercard plastique ou virtuelle</li>
	<li>50 cartes virtuelles business</li>
	<li>20 virements sortants et prélèvements par mois</li>
	<li>1 dépôt de chèque par mois</li>
	<li>Service clients par chat</li>
	<li>1 compte courant Professionnel</li>
	<li>1 compte courant Particulier</li>
</ul>
<h3>Premium</h3>
<li>3 cartes Mastercard plastique ou virtuelle</li>
<li>200 cartes virtuelles business</li>
<li>100 virements sortants et prélèvement par mois</li>
<li>4 dépôts de chèque par mois</li>
<li>Service client avec ligne téléphonique dédiée</li>
<li>1 compte courant Professionnel</li>
<li>1 compte courant Particulier
</li>
<h3>Professionnelle</h3>
<li>10 cartes Mastercard plastique ou virtuelle</li>
<li>500 cartes virtuelles business</li>
<li>500 virements sortants et prélèvement par mois</li>
<li>8 dépôts de chèque par mois</li>
<li>Service client avec ligne téléphonique dédiée</li>
<li>1 compte courant Professionnel</li>
<li>1 compte courant Particulier</li>
<li>Logiciel comptable inclus (valeur de 9.50€ / mois)</li>
<li>Tableau de gestion avec solde de tous vos comptes bancaires</li>
<h3>Entreprise</h3>
<li>20 cartes Mastercard plastique ou virtuelle</li>
<li>Cartes Mastercard avec le logo de votre société</li>
<li>1000 cartes virtuelles business</li>
<li>Virements sortants et prélèvements illimités</li>
<li>10 dépôts de chèque par mois
Service clients VIP avec une ligne téléphonique dédiée</li>
<li>1 compte courant Entreprise</li>
<li>Logiciel comptable inclus (valeur de 9.50€ / mois)</li>
<li>Tableau de gestion avec solde de tous vos comptes bancaires</li>

<h2>Anytime pour les créateurs d’entreprise</h2>
<h3>Pack dépôt de capital : 200€</h3>
<p>Dépôt de capital et attestation</p>

<p>+</p>

<p>Pour avoir accès à cette offre, vous devez souscrire à un compte Premium ou Professionnelle. Le pack dépôt de capital n’est pas disponible pour le compte solo.
</p>
<p>
Compte Premium : 19.50€ / mois<br></br>
Compte Professionnelle : 99.00€ / mois<br></br>
</p>


<h3>Pack création d’entreprise : 499€</h3>
<ul>
	<li>Rédaction des statuts</li>
	<li>Dépôt de capital</li>
	<li>Dépôt de dossier au greffe</li>
	<li>Remise du Kbis</li>
	<li>Annonce légale</li>
</ul>

<p>+</p>

<p>
De la même manière que pour le pack dépôt de capital, vous devez souscrire à un compte Premium ou Professionnelle pour accéder à cette offre. Le pack création n’est pas disponible pour le compte solo.
</p>
<p>
Compte Premium : 19.50€ / mois<br></br>
Compte Professionnelle : 99.00€ / mois<br></br>
</p>

<h3>
Logiciel comptable intégré : 9.50€/mois</h3>

<p>
Avec ce logiciel intégré à votre compte Pro Anytime vous pourrez :</p>

<ul>
	<li>Créer et éditer vos devis</li>
	<li>Créer et éditer vos factures</li>
	<li>Calcul de TVA</li>
	<li>Suivi de caisse</li>
	<li>Bilan et compte de résultat</li>
	<li>Import automatique des transactions anytime</li>
</ul>

<h3>Domiciliation de votre entreprise : 30€/mois</h3>
<ul>
<li>Choix d’adresse de domiciliation</li>
<li>Réexpédition gratuite de votre courrier</li>
<li>Sans engagement</li>
</ul>


<div style={{maxWidth: 800, margin: 'auto'}}>
			<StaticImage
		  	src='../../images/anytime-site.png'
			width={800}
			alt='site anytime'
			placeholder='tracedSVG'
			id='pickky'
			/>
			</div>

			<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
			<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Services Anytime <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>



	


	<h2>Gestion améliorée de votre entreprise – notre avis Anytime</h2>
	<h3>Payer / Acheter</h3>
<ul>
	<li>Virement multiple avec l’upload d’un fichier XML ou CSV</li>
	<li>Transfert rapide vers 30 devises hors zone SEPA</li>
</ul>

<h3>Recevoir des paiements</h3>

<h4>Terminal de paiement</h4>
<ul>
	<li>Accord privilégié avec SumUp et Smile&Pay</li>
</ul>

<h4>Prélèvements clients automatisés</h4>


<h4>Anytime et les chèques</h4>
<p>
Anytime accepte de le dépôt de chèques.</p>

<h4>Collaborateurs / Salariés</h4>
<ul>
	<li>Paiement des salaires</li>
	<li>Anytime donne la possibilité de payer tous vos salaires en une fois avec un simple upload XML ou CSV.</li>
</ul>

<p>Plus jamais à réclamer des notes de frais</p>
<ul>
	<li>Justificatif dématérialisé</li>
	<li>Calcul TVA automatique</li>
	<li>Comptabilité intégré</li>
</ul>

<h3>Comptabilité</h3>
<ul>
	<li>Outil d’édition de devis et factures</li>
	<li>Synchronisation avec votre logiciel comptable externes</li>
	<li>Logiciel comptable inclus</li>
</ul>

<h3>Avance de trésorerie</h3>
<p>Anytime travaille avec des partenaires de facturation qui permet une avance de trésorerie en cédant vos factures et bons de commandes.</p>


<Link to='/banque-pro/'> Comparez les offres de banque pros qui acceptent les dépôts de chèques</Link>

<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Aller sur le site <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>
	<h2>	La bankstore, c’est quoi ? Avis Anytime</h2>
<p>
Comme l’appstore d’Apple, Anytime permet à des sociétés tiers d’offrir des services supplémentaires à ses clients. L’idée est géniale, mais pour l’instant les offres sont assez limité : on notera Edebex qui permet des avances de trésoreries, gocardless pour les prélèvements automatique et fizen pour la comptabilité automatique.
</p>
<h2>Anytime et l’open banking</h2>
<p>
La particularité d’Anytime est son ouverture à technologie et à l’open banking. Elle propose un espace développeur qui permet aux sociétés clientes d’intégrer des briques bancaires AnyTime dans leur propre plateforme. Cet intégration se fait en marque blanche, donc invisible pour l’utilisateur.
</p><p>
Cette offre est particulièrement intéressante pour des marketplaces de type Uber. Anytime donne d’ailleurs l’exemple d’un chauffeur VTC qui en intégrant l’offre Uber ouvre directement un compte chez Anytime.
</p><p>
Avec ces fonctions, Anytime change de niveau avec une véritable offre B2B2B et va concurrencer les Tink et les Plaid du secteur.
</p>

<h2>Avis Anytime</h2>
<p>
Anytime a débuté sa vie en Belgique et est arrivée très vite en France. La société est devenue la première Néobanque réellement destinées aux indépendants et aux entreprises. Elle a levé au total 9.4M$ auprès d’investisseurs et a déjà plus de 100 000 clients actifs.
</p>
<p>
Avis Apple Store : 2.8 / 5<br></br>
Avis Google Play : 2.8 / 5
</p>
<p>
L’offre banque pro d’anytime est complète. Notre avis Anytime – elle correspond bien à la plupart des types de structure d’entreprise. L’obligation d’un abonnement annuel à la suite des packs créations rendent ses offres un peu contraignante mais aucun problème si de toute manière Anytime est la néobanque de votre choix. 
</p><p>
La bankstore ainsi que l’espace développeur qui surf sur l’open banking rende le future d’Anytime très excitant.
</p><p>
Anytime n’est pas une banque mais un néobanque, elle agit en tant qu’agent prestataire de service de paiement, enregistré par Treezor auprès de l’ACPR (Banque de France) sous le numéro 0846315003 et auprès de la FCA (Banque d’Angleterre) sous le numéro 902033.
</p>
<div style={{maxWidth: 300, margin: 'auto', marginBottom : 50, marginTop: 50}}>
<a href={go} target='_blank' rel="noreferrer">
	<Button id="voirOffre" type="submit" className="btn-lg" style={{height: 60, backgroundColor: 'rgb(240 0 87)', 
	borderColor: 'rgb(240 0 87)', color: 'white', fontSize: '1.25rem',
	fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"'
	}}>Ouvrir un compte <FontAwesomeIcon icon={faAngleDoubleRight} style={{marginTop: 5, marginBottom: -2, fontSize: 24, marginLeft: 10}}/></Button>
	</a>
	</div>

		</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default avis
